import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/solutions',
    name: 'solutions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue')
  },
  {
    path: '/blogs',
    name: 'blogs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blogs" */ '../views/BlogsView.vue')
  },
  {
    path: '/blogdetail/:id',
    name: 'blogdetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blogdetail" */ '../views/BlogDetailView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView.vue')
  },
  {
    path: '/admindashboard',
    name: 'admindashboard',
    component: () => import(/* webpackChunkName: "admindashbaord" */ '../views/AdminDashboard.vue'),
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/case-study',
    name: 'case-study',
    component: () => import(/* webpackChunkName: "case-study" */ '../views/CaseStudyView.vue')
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import(/* webpackChunkName: "career" */ '../views/CareersView.vue')
  },
  {
    path: '/job-detail/:id',
    name: 'job-detail',
    component: () => import(/* webpackChunkName: "job-detail" */ '../views/JobDetailView.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "job-detail" */ '../views/FAQsView.vue')
  },

]

/* eslint-disable*/
const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
})

export default router

<template>
  <section class="pb-0" id="Industries">
    <div class="container">
      <div class="h1">We're not for everyone.</div>
      <div class="h6 mb-5">
        We help startups and midsize businesses in these spaces
      </div>

      <div class="row" style="margin-top: 3rem">
        <div class="col-2">
          <div class="d-flex justify-content-center education_technology_hover">
            <img src="../assets/home_page_icons/ed_tech_icon.png" width="80" />
          </div>
          <div style="font-size: 18px; font-weight: 500;line-height: 1.2;margin-top: 1rem; text-align: center;">
            Education<br>Technology</div>
        </div>
        <div class="col-2">
          <div class="d-flex justify-content-center retail_technology_hover">
            <img src="../assets/home_page_icons/retail_tech.png" width="80" />
          </div>
          <div style="font-size: 18px; font-weight: 500; text-align: center;line-height: 1.2;margin-top: 1rem;">
            Retail<br>Technology</div>
        </div>
        <div class="col-2">
          <div class="d-flex justify-content-center finance_technology_hover">
            <img src="../assets/home_page_icons/fin_tech_icon.png" width="80" />
          </div>
          <div style="font-size: 18px; font-weight: 500; text-align: center;line-height: 1.2;margin-top: 1rem;">
            Retail<br>Technology</div>
        </div>

        <!-- <div class="col-2">
          <div class="d-flex justify-content-center finance_technology_hover">
            <img src="../assets/home_page_icons/fin_tech_icon.png" width="80" />
          </div>
          <div style="font-size: 18px; font-weight: 500; text-align: center;line-height: 1.2;margin-top: 1rem;">
            Finance<br>Technology</div>
        </div> -->


        <div class="col-2">
          <div class="d-flex justify-content-center manufacturing_industries_hover">
            <img src="../assets/home_page_icons/manufacturing_industries_icon.png" width="80" />
          </div>
          <div style="font-size: 18px; font-weight: 500; text-align: center;line-height: 1.2;margin-top: 1rem;">
            Manufacturing
            <br>Industries
          </div>
        </div>
        <div class="col-2">
          <div class="d-flex justify-content-center fitness_technology_hover">
            <img src="../assets/home_page_icons/fitness_tech_icon.png" width="80" />
          </div>
          <div style="font-size: 18px; font-weight: 500; text-align: center;line-height: 1.2;margin-top: 1rem;">Fitness
            <br>Technology
          </div>
        </div>
        <div class="col-2">
          <div class="d-flex justify-content-center co_working_spaces_hover">
            <img src="../assets/home_page_icons/co_work_space_icon.png" width="80" />
          </div>
          <div style="font-size: 18px; font-weight: 500; text-align: center;line-height: 1.2;margin-top: 1rem;">Co-working
            <br>spaces
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-2 fitness_technology_hover">
          <img
            src="../assets/icons/we_are_for/fitness_technology.svg"
            style="height: 10rem; width: 10rem"
          />
        </div>
        <div class="col-2 retail_technology_hover">
          <img
            src="../assets/icons/we_are_for/retail_technology.svg"
            style="height: 10rem; width: 10rem"
          />
        </div>
        <div class="col-2 finance_technology_hover">
          <img
            src="../assets/icons/we_are_for/finance_technology.svg"
            style="height: 10rem; width: 10rem"
          />
        </div>
        <div class="col-2 co_working_spaces_hover">
          <img
            src="../assets/icons/we_are_for/co_working_spaces.svg"
            style="height: 10rem; width: 10rem"
          />
        </div>
        <div class="col-2 manufacturing_industries_hover">
          <img
            src="../assets/icons/we_are_for/manufacturing_industries.svg"
            style="height: 10rem; width: 10rem"
          />
        </div>
        <div class="col-2 education_technology_hover">
          <img src="../assets/icons/we_are_for/education_technology.svg" />
        </div>
      </div> -->
    </div>
    <div class="d-flex justify-content-center" style="margin-top: 3rem">
      <a hef="#" class="text-uppercase text-primary fw-bold" style="color: #105da1; font-weight: 700"><u>View all
          industries</u></a>
    </div>
  </section>
</template>

<script>
/* eslint-disable */

export default {
  name: "WeAreFor",
};
</script>

<style>
.fitness_technology_hover:hover img {
  content: url("../assets/home_page_icons/fit_tech.svg");
}

.retail_technology_hover:hover img {
  content: url("../assets/home_page_icons/retail_tech.svg");
}

.finance_technology_hover:hover img {
  content: url("../assets/home_page_icons/finance_tech.svg");
  /* height: 9rem; */
}

.co_working_spaces_hover:hover img {
  content: url("../assets/home_page_icons/co_work_spaces.svg");
}

.manufacturing_industries_hover:hover img {
  content: url("../assets/home_page_icons/manu_industries.svg");
}

.education_technology_hover:hover img {
  content: url("../assets/home_page_icons/ed_tech.svg");
}
</style>

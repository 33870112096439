<template>
  <section class="pb-0" style="padding-top: 2rem">
    <div class="container">
      <div class="d-flex align-items-center">
        <div class="row">
          <div class="col-6">
            <div class="" style="margin-top: 5rem">
              <div class="row">
                <div class="col-12">
                  <div class="h1">Recognized by the best</div>
                </div>
                <div class="col-12">
                  <span>The company needed to complete a complex migration on a
                    tight deadline to avoid millions of dollars in post-contract
                    fees and fines.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <img src="../assets/images/all_clients.svg" title="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */

export default {
  name: "OurClients",
};
</script>

<template>
    <div class="blog-details">
        <img class="bg"
            v-bind:src="blog.image" />
        <div class="container-1280">
            <div class="margin-bottom-6">
                <h1 class="text-color-4">"{{blog.title}}"</h1>
                <div class="text-color-4">{{blog.timestamp}}<br></div>
            </div>
            <p><em><strong>Author - <span>{{blog.author}}</span></strong></em></p>
            <p v-html="blog.description"></p>
        </div>
    </div>
</template>


<script>
/* eslint-disable */
//firebase
import { collection, getDocs } from 'firebase/firestore';
import { db } from "@/firebase";
export default {
    name: 'BlogDetail',
    data(){
        return{
            blog:{}
        }
    },
   async mounted() {
    let index = this.$route.params.id;
    const querySnapShot = await getDocs(collection(db, 'blogs'))
    let blogsList = []
    querySnapShot.forEach(element => {
      blogsList.push(element.data())
    });
    this.blog = blogsList[index]
    // console.log("data is", this.blog.title);
  }
}
</script>


<style>
.blog-details {
    padding-bottom: 100px;
    padding-top: 70px;
}

.bg {
    width: 100%;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.container-1280 {
    width: 100%;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.margin-bottom-6 {
    margin-bottom: 1.375rem;
    margin-top: 1.375rem;
}


b, strong {
    color: #000;
    font-weight: 700;
}

</style>
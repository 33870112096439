<template>
  <section class="pb-0" style="background-color: #231f20; margin-top: 3rem" id="about_us">
    <div class="container">
      <div class="h1 services-heading">
        This isn't just another development process.
      </div>
      <div class="h1 services-heading">
        This is your master plan for success.
      </div>
      <div>
        <p class="services-paragraph" style="margin-bottom: 5px">
          We stand by our custom, agile methodology of product development.
        </p>
        <p class="services-paragraph">
          So you get only superior quality products that go through rigorous
          improvements -Delivered to you 2x faster.
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <img src="../assets/images/dev_process.png" title="" width="900" />
      </div>
      <div class="d-flex justify-content-center services-paragraph mt-4">
        An app you will love + grow your business with.
      </div>
      <div class="d-flex justify-content-center mb-5 services-paragraph">
        Built by a visionary, reliable, and experienced team.
      </div>

      <div class="d-flex justify-content-center pb-5">
        <a href="#contact_us">
          <button class="btn btn-primary text-uppercase mx-2">
            Let's talk
          </button>
        </a>
        <a href="#contact_us">
          <button class="btn btn-white text-uppercase mx-2">
            I Want to know more
          </button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */

export default {
  name: "DevelopmentProcess",
};
</script>

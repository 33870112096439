<template>
  <section class="pb-0" style="background-color: #001155; height: 40rem" id="case_Study">
    <div class="container">
      <div style="margin-top: 50px; margin-bottom: 50px" class="align-items-center pbt" :style="{
        display: 'flex',
        'flex-direction': deviceType == 'mobile' ? 'column' : 'row',
      }">
        <div class="col-lg-6">
          <div class="mb-lg-0 mb-5 bodydata" style="margin-top: 4rem">
            <div class="box-testimonial">
              <img src="../assets/images/2.png" height="100" width="100" />
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 300; margin-bottom: 10px;">
                Mental Health &
                Fitness</p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 17px; font-weight: 500;margin-bottom: 10px;">A
                user-friendly Mobile App.
                An Operations friendly Web App.
                A Responsive Website.</p>

              <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300; margin-bottom: 10px;">
                iDare transformed
                the mental
                health industry by helping people recognise, prevent and fight against abuse with a holistic app.</p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">
                iDare saved a
                Kashmiri teenager from domestic abuse with this App!</p>
              <ol style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 700;">
                <li>93K App Launches</li>
                <li>4 mins 5 sec Avg Engagement time</li>
                <li>8K + unique global downloads</li>
              </ol>
              <a href="#contact_us">
                <button style="background-color:  var(--primary-color); color: #ffffff;" class="btn btn-primary text-uppercase b-width"
                  @click="handleClick(index)">
                  TELL ME HOW YOU DID IT
                </button>
              </a>
            </div>


            <div class="box-testimonial">
              <img src="../assets/home_page_icons/hoc_icon.svg" height="150" width="150" />
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 300;margin-bottom: 10px;">
                Luxury eCommerce</p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 17px; font-weight: 500;margin-bottom: 10px;">The
                ULTIMATE, tailor-made, and secure
                Web eCommerce Marketplace..</p>

              <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">
                House of creativity takes the art industry online by introducing one of a kind design and highly secure
                marketplace. From guest checkout to insuring art on transit everything is taken care. The concept
                completely transforms the way art is purchased.</p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">
                Customers have bought single artwork as high as $5K USD!</p>
              <ol style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 700;">
                <li>Design Driven Development</li>
                <li>Responsive Web App</li>
                <li>Multi-currency and global shipment</li>
              </ol>
              <a href="#contact_us">
                <button style="background-color:  var(--primary-color); color: #ffffff;" class="btn btn-primary text-uppercase b-width"
                  @click="handleClick(index)">
                  TELL ME HOW YOU DID IT
                </button>
              </a>
            </div>


            <div class="box-testimonial">

              <img src="../assets/images/flash_n_tap_logo.svg" height="100" width="100" />
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 300;margin-bottom: 10px;">
                Retail Technology</p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 17px; font-weight: 500;margin-bottom: 10px;">Game-
                Changing, light-weight, user friendly and cost effective mobile app.</p>

              <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">
                One of the most innovative products in Canada. It is an IoT based product with an IoT device, mobile and
                web app.
                Customers visiting places like Target or Walmart stores can tap their phone to receive invoices, track
                expenses and showcase saving and cheaper alternative stores.</p>

              <ol style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 700;">
                <li>Native Android and iOS apps</li>
                <li>BLE and NFC technology</li>
                <li>IBM backed product</li>
              </ol>

              <a href="#contact_us">
                <button style="background-color:  var(--primary-color); color: #ffffff;" class="btn btn-primary text-uppercase b-width"
                  @click="handleClick(index)">
                  TELL ME HOW YOU DID IT
                </button>
              </a>
            </div>


            <div class="box-testimonial">
              <img src="../assets/images/pathfinder_logo.svg" height="100" width="100" />
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 300;margin-bottom: 10px;">
                PharmaTech</p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 17px; font-weight: 500;margin-bottom: 10px;">
                Pathfinder Business intelligence analytics can fundamentally disrupt current disease management, allowing
                a higher degree of predictability in care delivery and better insights into resource allocation.</p>

              <!-- <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">
                They are an Exceptional team with clear leadership</p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">
                We are impressed by their commitment, speed and knowledge.</p>

              <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">
                Quality of delivery, Design skills and Seamless integration of desktop to mobile environment are the
                reasons for our success story.</p> -->
              <ol style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 700;">
                <li>Quick Scalable prototype.</li>
                <li>Cross-platform Mobile App.</li>
                <li>Flutter Framework.</li>
              </ol>
              <button style="background-color:  var(--primary-color); color: #ffffff;" class="btn btn-primary text-uppercase b-width"
                @click="handleClick(index)">
                TELL ME HOW YOU DID IT
              </button>
            </div>



            <div class="box-testimonial">
              <img src="../assets/images/ambimat_logo.png" height="100" width="100" />
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 300; margin-bottom: 10px;">
                IoT Technology</p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 17px; font-weight: 500; margin-bottom: 10px;">
                Ambimat’s smart heat sensor technology helps various businesses in view and recording temperature of cold
                storage, hot storage and other temperature sensitive products. The app uses BLE and NFC technology for
                communication..</p>

              <!-- <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">They have
                a good core team and access to developers with a variety of skill set
              </p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">
                ThinkAlt has a good team! Keep up the good work
              </p>
              <p style="color: #B7CEE3;line-height: 1.3; font-size: 14px; font-weight: 300;margin-bottom: 10px;">
                Unexpected benefit is the fact that a Xamarin developer was available with ThinkAlt
              </p> -->
              <ol style="color: #B7CEE3;line-height: 1.3; font-size: 15px; font-weight: 700;">
                <li>Hybrid App with heat sensor IOT Module.</li>
                <li>Xamarin Mobile App Framework.</li>
              </ol>
              <button style="background-color:  var(--primary-color); color: #ffffff;" class="btn btn-primary text-uppercase b-width"
                @click="handleClick(index)">
                TELL ME HOW YOU DID IT
              </button>
            </div>


          </div>
          <!--  <h3 class="testimonial-title">
            Custom app solutions for complex products.
          </h3>
          <p class="testimonial-paragraph">
            A scalable, light-weight, user-friendly, and cost-effective mobile
            app
          </p>
          <p class="testimonial-paragraph">
            How we helped Inner Sight reduce their app's maintenance and support
            cost by 50%
          </p> -->
          <!-- <div class="row">
            <div class="col-10">
              <button
                class="btn btn-primary text-uppercase"
                @click="handleClick(index)"
              >
                Tell me how you did it
              </button>
            </div>
          </div> -->
        </div>
        <div class="col-lg-6" :style="{ 'margin-top': deviceType == 'mobile' ? '400px' : '0' }">
          <div class="mb-lg-0 mb-5 bodydata" style="margin-top: 9rem">
            <div class="media1" v-for="card in testimonial_cards" :key="card.title">
              <div class="">
                <img class="testimonial_cards_logo" :src="card.client_image" />
                <div style="display: flex; flex-direction: row ;">
                  <img :src="card.logo" width="60" />
                  <div style="margin-left: 10px;">
                    <h5 style="font-weight: 500;">{{ card.client_name }}</h5>
                    <h5 style="font-weight: 500;">{{ card.client_role }}</h5>
                  </div>
                </div>
                <p style="font-weight: 300;font-size: 13px; color: #2c3137;line-height: 1.3;margin-top: 10px;">
                  {{ card.description }}
                </p>
                <!-- <img class="testimonial_cards_logo" :src="card.logo" />
                <div class="media-body-new justify-content-center">
                  <h5 class="testimonial-card-title">{{ card.title }}</h5>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */

export default {
  name: "TestimonialsApp",
  data() {
    return {
      // words: [
      //   {
      //     title: "Lorem Ipsum",
      //     description:
      //       "Jack Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew",
      //   },
      //   {
      //     title: "Lorem Ipsum",
      //     description:
      //       "Jack Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew",
      //   },
      //   {
      //     title: "Lorem Ipsum",
      //     description:
      //       "Jack Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew",
      //   },
      //   {
      //     title: "Lorem Ipsum",
      //     description:
      //       "Jack Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew",
      //   },
      //   {
      //     title: "Lorem Ipsum",
      //     description:
      //       "Jack Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew",
      //   },
      // ],
      i: 0,
      deviceType: window.innerWidth <= 900 ? "mobile" : "desktop",
      slideTransition: "-100%",
      testimonial_cards: [
        /*   {
          title: "The baby claridges",
          description:
            "Jack Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew",
          logo: "../img/1.png",
        }, */
        {
          client_name: "Vaamaa Baldota",
          client_role: "CEO, iDare Bengaluru",
          client_words:
            "<p>Good Team! Committed to your mission! Quality of work! <p <p> The process was streamlined and organized very well on Trello. Made it easy and efficient to keep track of bugs, new requirements and builds. </p> <p>Really enjoyed working with ThinkAlt! Would highly recommend them.</p>",
          title: "iDare",
          description:
            "Good Team! Committed to your mission! Quality of work! The process was streamlined and organized very well on Trello. Made it easy and efficient to keep track of bugs, new requirements and builds.Really enjoyed working with ThinkAlt! Would highly recommend them.",
          logo: "../img/idare.png",
        },
        {
          client_name: "Luv Sinha",
          client_role: "CEO, House of Creativity Mumbai- India",
          client_words:
            "<p>Good Team! Committed to your mission! Quality of work! <p <p> The process was streamlined and organized very well on Trello. Made it easy and efficient to keep track of bugs, new requirements and builds. </p> <p>Really enjoyed working with ThinkAlt! Would highly recommend them.</p>",
          title: "iDare",
          description:
            "The team was helpful and went out of their way at times. Their support and efficiency are the reasons why we would recommend them. Their support and efficiency are the reasons why we would recommend them.",
          logo: "../img/house_of_creativity.png",
        },
        {
          client_name: "Venkata G Thota",
          client_role: "COO, Apeirogon Labs Canada",
          client_words:
            "<p>Good Team! Committed to your mission! Quality of work! <p <p> The process was streamlined and organized very well on Trello. Made it easy and efficient to keep track of bugs, new requirements and builds. </p> <p>Really enjoyed working with ThinkAlt! Would highly recommend them.</p>",
          title: "iDare",
          description:
            "Technically strong team that listen and understand what we want and timely deliverables. App is successfully completed with backend support. Friendly knowledge transfer to our in-house team. Top 3 reasons to recommend- Talent, Behavior and Focus",
          logo: "../img/apeir_labs.png",
        },
        {
          client_name: "Anand Srinivasan",
          client_role: "Weathervane Pte Ltd",
          client_words:
            "<p>Good Team! Committed to your mission! Quality of work! <p <p> The process was streamlined and organized very well on Trello. Made it easy and efficient to keep track of bugs, new requirements and builds. </p> <p>Really enjoyed working with ThinkAlt! Would highly recommend them.</p>",
          title: "iDare",
          description:
            "They are an Exceptional team with clear leadership. We are impressed by their commitment, speed and knowledge. Quality of delivery, Design skills and Seamless integration of desktop to mobile environment are the reasons for our success story.",
          logo: "../img/profile_placeholder.jpg",
        },
        {
          client_name: "Neil Shah",
          client_role: "Ambimat Electronics",
          client_words:
            "<p>Good Team! Committed to your mission! Quality of work! <p <p> The process was streamlined and organized very well on Trello. Made it easy and efficient to keep track of bugs, new requirements and builds. </p> <p>Really enjoyed working with ThinkAlt! Would highly recommend them.</p>",
          title: "iDare",
          description:
            "They have a good core team and access to developers with a variety of skill set. ThinkAlt has a good team! Keep up the good work. Unexpected benefit is the fact that a Xamarin developer was available with ThinkAlt",
          logo: "../img/profile_placeholder.jpg",
        },
        /*  {
          title: "TutorNex",
          description:
            "Jack Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew",
          logo: "../img/6.png",
        }, */
        /*   {
          title: "ANTONIO DIAMOND",
          description:
            "Jack Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew Lorem Ipsumsdvsdsvdvsdvgsadvgsavzv zsrtew",
          logo: "../img/7.png",
        }, */
      ],
    };
  },
  methods: {
    getIMGPath(img) {
      return img ? require("@/assets/images/" + img) : "";
    },
    mounted() {
      // if (this.deviceType == 'desktop') {
      //     this.slideTransition = '100%'
      // } else {
      //     this.slideTransition = '-100%'
      // }
      // this.slideTransition = deviceType == 'mobile' ? '-100%' : '100%'
      // setInterval(() => {
      //     if (this.i < this.words.length - 1) this.i++
      //     else this.i = 0
      // }, 3000)
    },
  },
};
</script>

<style>
.bodydata {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.b-width {
  width: 220px;
}

.testimonial-card-title {
  padding: auto;
  font-size: 30px;
  font-style: italic;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-align: center;
}

.testimonial_cards_logo {
  width: 10rem;
  margin-right: 5rem;
  margin-left: 1rem;
}

.testimonial-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #b7cee3;
  margin-top: -2.5rem;
  margin-bottom: 2.5rem;
}

.testimonial-paragraph {
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #b7cee3;
  margin-bottom: 30px;
}

.box-testimonial {
  text-align: left;
  vertical-align: middle;
  position: absolute;
  display: grid;
  padding: 5px 10px;
  box-shadow: rgba(233, 232, 232, 0.35) 0px 0px 0px;
  border-radius: 10px;
  gap: 10px;
  opacity: 0;
  animation: slideUpText 20s linear infinite;
}

.box-testimonial:nth-child(1) {
  animation-delay: -4s;
}

.box-testimonial:nth-child(2) {
  animation-delay: 0s;
}

.box-testimonial:nth-child(3) {
  animation-delay: 4s;
}

.box-testimonial:nth-child(4) {
  animation-delay: 8s;
}

.box-testimonial:nth-child(5) {
  animation-delay: 12s;
}

.pbt {
  padding-bottom: 3.75rem;
}

.media1 {
  position: absolute;
  display: flex;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  gap: 10px;
  opacity: 0;
  animation: slideUp 20s linear infinite;
  background: linear-gradient(99.77deg,
      #ffffff 1.05%,
      rgba(245, 245, 245, 0.8) 21.02%,
      rgba(255, 255, 255, 0.6) 63.52%,
      rgba(255, 253, 253, 0.2) 79.39%,
      #ffffff 98.94%);
  width: 30.5rem;
  height: 12.75rem;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

/* card styles */
.circle-icon {
  color: #6e73ef;
}

.circle-icon {
  z-index: 0;
  width: 80px;
  height: 80px;
  margin-right: 10px;
  color: #ec0e0e;
  position: relative;
  line-height: 80px;
  text-align: center;
}

.circle-icon i {
  font-size: 40px;
}

.media-body-new {
  flex: 1;
}

/* card styles ends */

.media1:nth-child(1) {
  animation-delay: -4s;
}

.media1:nth-child(2) {
  animation-delay: 0s;
}

.media1:nth-child(3) {
  animation-delay: 4s;
}

.media1:nth-child(4) {
  animation-delay: 8s;
}

.media1:nth-child(5) {
  animation-delay: 12s;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
  }

  5%,
  20% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
  }

  25%,
  40% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  45%,
  60% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
  }

  65%,
  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
  }
}

@keyframes slideUpText {
  0% {
    transform: translateX(v-bind(slideTransition)) scale(0.5);
    opacity: 0;
  }

  5%,
  20% {
    transform: translateX(v-bind(slideTransition)) scale(0.7);
    opacity: 0;
  }

  25%,
  40% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }

  45%,
  60% {
    transform: translateX(100%) scale(0.7);
    opacity: 0;
  }

  65%,
  100% {
    transform: translateX(100%) scale(0.5);
    opacity: 0;
  }
}
</style>

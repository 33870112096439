<template>
  <!-- <REVSlider /> -->
  <CarouselSlider />
  <Services v-bind:servicesList="servicesList" />
  <Solutions />
  <!-- <Body /> -->
  <Testimonials />
  <WeAreFor />
  <DevelopmentProcess />
  <OurClients />
  <!-- <Blogs v-bind:blogsList="blogsList" /> -->
  <!-- <Partners /> -->
  <ContactForm />
</template>

<script>
/* eslint-disable */

import REVSlider from "../components/REVSlider.vue";
import Body from "../components/Body.vue";
import Partners from "../components/Partners.vue";
import Blogs from "../components/Blogs.vue";
import Services from "../components/Services.vue";
import Testimonials from "../components/Testimonials.vue";
import ContactForm from "@/components/ContactForm.vue";
import CarouselSlider from "@/components/CarouselSlider.vue";
import Solutions from "@/components/Solutions.vue";
import WeAreFor from "@/components/WeAreFor.vue";
import DevelopmentProcess from "@/components/DevelopmentProcess.vue";
import OurClients from "@/components/OurClients.vue";

// import ContactFormVue from '@/components/ContactForm.vue';

//firebase
import { collection, getDocs } from "firebase/firestore";
import { db } from "@/firebase";

export default {
  name: "HomeView",
  components: {
    REVSlider,
    Body,
    Partners,
    Blogs,
    Services,
    Testimonials,
    ContactForm,
    CarouselSlider,
    Solutions,
    WeAreFor,
    DevelopmentProcess,
    OurClients,
  },
  data() {
    return {
      blogsList: [],
      servicesList: [
        {
          title: "Android App Development",
          description: "",
          image:
            "https://firebasestorage.googleapis.com/v0/b/that-72d39.appspot.com/o/images%2FAndroid%20App%20Dev.png?alt=media&token=33d24925-928e-48ef-8b0a-535cd8f5ad79",
        },
        {
          title: "iOS App Development",
          description: "",
          image:
            "https://firebasestorage.googleapis.com/v0/b/that-72d39.appspot.com/o/images%2Fios%20App%20Dev.png?alt=media&token=f50a70d6-4c53-4951-a741-bced30ad456f",
        },
        {
          title: "Hybrid App Development",
          description: "",
          image:
            "https://firebasestorage.googleapis.com/v0/b/that-72d39.appspot.com/o/images%2FHybrid%20App%20Dev.png?alt=media&token=7589f9cb-3fa1-49e5-a913-5a97b302788b",
        },
        {
          title: "Web App Development",
          description: "",
          image:
            "https://firebasestorage.googleapis.com/v0/b/that-72d39.appspot.com/o/images%2FWeb%20App%20Dev.png?alt=media&token=8451c09b-5098-4146-adae-f50fd38f6e06",
        },
      ],
    };
  },
  async mounted() {
    const querySnapShot = await getDocs(collection(db, "blogs"));
    querySnapShot.forEach((element) => {
      this.blogsList.push(element.data());
    });
    this.blogsList.splice(0, 2);
  },
};
</script>

<template>
  <!-- <VueCarousel :data="data"></VueCarousel> -->
  <div>
    <section class="pb-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <div style="text-align: left">
              <h2 style="font-weight: bold; line-height: 2.7rem">
                Get your hands on a <br />
                working, profitable, and scalable app.
              </h2>
              <h5 style="font-weight: 500 !important; margin-top: 0.8rem">
                Helping Startups and midsize businesses worldwide
              </h5>
              <p style="line-height: 1rem" class="mt-3">
                Grow, scale, and transform your business digitally. With the app
                of your dreams. Built to perfection by team Think Alternate
                Tech.
              </p>

              <ul
                style="
                  color: black;
                  font-size: 13px;
                  list-style-type: none;
                  margin-left: -25px;
                "
              >
                <li>
                  <img
                    src="../assets/icons/list_item_icon.svg"
                    style="max-width: 15px; min-width: 15px"
                  />
                  Experts in Native, Flutter, and React-Native cross-platform
                  app development
                </li>
                <li>
                  <img
                    src="../assets/icons/list_item_icon.svg"
                    style="max-width: 15px; min-width: 15px"
                  />
                  You own the app, code
                </li>
                <li>
                  <img
                    src="../assets/icons/list_item_icon.svg"
                    style="max-width: 15px; min-width: 15px"
                  />
                  Free consultation
                </li>
                <li>
                  <img
                    src="../assets/icons/list_item_icon.svg"
                    style="max-width: 15px; min-width: 15px"
                  />
                  30-days money-back guarantee
                </li>
              </ul>

              <div>
                <a href="#contact_us">
                  <button
                    class="btn btn-primary text-uppercase"
                    style="margin-right: 1rem; width: 15rem;"
                  >
                    Build My App
                  </button>
                </a>
                <a href="#case_Study">
                  <button
                    class="btn btn-white color-primary text-uppercase home-past-work-btn"
                    style="width: 16rem;"
                  >
                    Show me your Past Works
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-lg-0 mb-5">
            <img style="max-width: 120%" src="../assets/home_page_icons/header_image.svg" title="" />
          </div>
        </div>
      </div>
    </section>
    <hr style="margin: 50px 50px" class="solid" />

    <section style="padding-top: 0" class="pb-0 text-dark">
      <div class="container d-flex justify-content-around">
        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bolder h2 show-case-numbers"
          >
            12+ Years
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Industry Leader
          </div>
        </div>

        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            120+
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Clients Served
          </div>
        </div>

        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            125+
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Apps Built
          </div>
        </div>

        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            100+
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Consulting Services
          </div>
        </div>

        <div class="show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            11,500+
          </div>
          <div
            class="d-flex justify-content-center text-justify show-case-numbers-title"
          >
            <div>Hours Dedicated to</div>
          </div>
          <div
            class="d-flex justify-content-center text-justify show-case-numbers-title"
          >
            Client's Growth
          </div>
        </div>
      </div>
    </section>

    <section style="padding-top: 0" class="pb-0 mt-3 text-dark">
      <div
        class="container d-flex justify-content-center show-case-numbers-title"
      >
        <div class="px-5 show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            20+
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Team Members
          </div>
        </div>

        <div class="px-5 show-case-numbers-hover-class">
          <div
            class="d-flex justify-content-center font-weight-bold h2 show-case-numbers"
          >
            9/10
          </div>
          <div class="d-flex justify-content-center show-case-numbers-title">
            Clients Referrals
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import VueCarousel from '@chenfengyuan/vue-carousel';
export default {
  components: {
    // VueCarousel,
  },
  data() {
    return {
      data: [
        '<section class="pb-0"><div class="container"><div class="row align-items-center"><div class="col-lg-6"><div style="text-align:left"> <h1>Quality is the best <br>business plan.</h1><p>There are manyvariations of passages ofLorem Ipsum<br> available, but the majority have suffered alterat.</p>   <button class="button" style="background:linear-gradient(90deg, #3b91e7 0%, #4ac4f3 100%)">Read More</button></div></div><div class="col-lg-6 mb-lg-0 mb-5"><img src="https://firebasestorage.googleapis.com/v0/b/that-72d39.appspot.com/o/img-1.9719a644.png?alt=media&token=e4572bf6-f333-4ce7-baa1-4eb6fe581b42" title="" ></div></div></div></section>',
        '<section class="pb-0"><div class="container"><div class="row align-items-center"><div class="col-lg-6"><div style="text-align:left"> <h1>Quality is the best <br>business plan.</h1><p>There are manyvariations of passages ofLorem Ipsum<br> available, but the majority have suffered alterat.</p>   <button class="button" style="background:linear-gradient(90deg, #3b91e7 0%, #4ac4f3 100%)">Read More</button></div></div><div class="col-lg-6 mb-lg-0 mb-5"><img src="https://img.freepik.com/free-vector/bangalore-india-skyline-silhouette-with-panorama-white-background-vector-illustration-business-travel-tourism-concept-with-modern-buildings-image-presentation-banner-website_596401-299.jpg?w=2000" title="46"></div></div></div></section>',
      ],
    };
  },
};
</script>

<style>
.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  height: 600px;
}

.vl {
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 2px;
  border-left: 3px solid rgb(192, 192, 192);
  height: 25px;
}

.p1 {
  font-size: 15px;
  color: #242627;
  line-height: 1.1;
}

.p2 {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  line-height: 1;
}
</style>

<template>
  <footer class="position-relative org-menu footer-two p-0 custom-footer" style="background-color: #001155">
    <div class="container">
      <div class="row footer-links-list">
        <div class="col-2">
          <ul class="list-unstyled">
            <li style="font-weight: 700">Company</li>
            <li style="font-size: 14px;">About Us</li>
            <li style="font-size: 14px;">Case Studies</li>
            <!-- <li style="font-size: 14px;">Terms & Conditions</li> -->
          </ul>
        </div>
        <div class="col-2">
          <ul class="list-unstyled">
            <li style="font-weight: 700">Solutions</li>
            <li style="font-size: 14px;">Proof of Concept</li>
            <li style="font-size: 14px;" class="footer-line-height">
              Minimal Viable Product development
            </li>
            <li style="font-size: 14px;">
              Product Development
            </li>
            <li style="font-size: 14px;">
              Product Revamp
            </li>
            <li style="font-size: 14px;" class="footer-line-height">Fractional CTO</li>
            <li style="font-size: 14px;" class="footer-line-height">Mobile App Support</li>
            <li style="font-size: 14px;" class="footer-line-height">Mobile App Maintenance</li>
            <li style="font-size: 14px;" class="footer-line-height">Mobile App Audit</li>
          </ul>
        </div>
        <div style="margin-bottom: 3rem;" class="col-2">
          <ul class="list-unstyled">
            <li style="font-weight: 700">Services</li>
            <li style="font-size: 14px;" class="footer-line-height">Android App development</li>
            <li style="font-size: 14px;" class="footer-line-height">IOS App Development</li>
            <li style="font-size: 14px;" class="footer-line-height">Hybrid App Development</li>
            <li style="font-size: 14px;" class="footer-line-height">Web App Development</li>
            <li style="font-size: 14px;" class="footer-line-height">UI/UX Design</li>
            <li style="font-size: 14px;" class="footer-line-height">Mobile App Consulting</li>
          </ul>
        </div>


        <img style="margin-left: 55%; position:absolute;" src="../assets/globe.svg" />
        <!-- <div class="col-2">
          <ul class="list-unstyled">
            <li style="font-weight: 900">Career</li>
            <li>Our Culture</li>
            <li>Gallery</li>
            <li>Recreation</li>
            <li>Job Openings</li>
            <li>Why Work for us?</li>
          </ul>
        </div> -->

        <!-- <div class="col-2">
          <ul class="list-unstyled">
            <li style="font-weight: 900">Case Study</li>
            <li>Case Study 1</li>
            <li>Case Study 2</li>
            <li>Case Study 3</li>
            <li>Case Study 4</li>
            <li>Case Study 5</li>
          </ul>
        </div> -->

        <!-- <div class="col-2">
          <ul class="list-unstyled">
            <li style="font-weight: 900">Blogs</li>
            <li>Blogs 1</li>
            <li>Blogs 2</li>
            <li>Blogs 3</li>
            <li>Blogs 4</li>
            <li>Blogs 5</li>
          </ul>
        </div> -->

        <div>
          <div style="margin-top: 5rem; margin-bottom: 3rem">
            <div class="d-flex justify-content-start">
              <input type="text" placeholder="Don't Miss out on Updates" style="width: 40rem" />
            </div>

            <div class="d-flex justify-content-start">
              <input type="checkbox" aria-label="I agree" style="margin-top: -0.2rem" />
              <span style="font-size: 14px; line-height: 1.5rem">I agree to the privacy policy and give the permission to
                process my data <br />
                for the purpose specified in the Privacy Policy.</span>
            </div>
            <div>
              <button class="btn btn-primary text-uppercase mt-3 btn-lg" style="width: 9rem; font-size: 15px">
                SEND
                <img style="margin-left: 15px" src="../assets/icons/arrow_forward.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: #ffffff">
      <div class="row justify-content-center footer-subscribe">
        <!-- <ContactForm/>  -->
        <div class="col-lg-12 text-center">
          <!-- <section class="footer-info iq-pt-60"> -->
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-2">
                <div class="iq-footer-box text-left">
                  <div class="iq-icon">
                    <i aria-hidden="true" class="fa fa-map-marker"></i>
                  </div>
                  <div class="footer-content" style="border-right: #666464 1.5px solid;">
                    <p class="p1" style="font-size: 12px;line-height: 1.5;">
                      <span style="font-weight: 600;padding-bottom: 10px;">Address</span><br>
                      WeWork, 43, Residency Rd,<br>
                      Bengaluru, 560025
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-2 r4-mt-30">
                <div class="iq-footer-box text-left">
                  <div class="iq-icon">
                    <i aria-hidden="true" class="fa fa-phone"></i>
                  </div>
                  <div class="footer-content" style="border-right: #666464 1.5px solid;">
                    <p class="p1" style="font-size: 12px;line-height: 1.5;">
                      <span style="font-weight: 600;padding-bottom: 10px;">Phone</span><br>
                      +91 6379232460<br />Mon-Sat
                      10:00am - 8:00pm<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-2 r-mt-30">
                <div class="iq-footer-box text-left">
                  <div class="iq-icon">
                    <i aria-hidden="true" class="fa fa-envelope"></i>
                  </div>
                  <div class="footer-content">
                    <p class="p1" style="font-size: 12px; line-height: 1.5;">
                      <span style="font-weight: 600;padding-bottom: 10px;">E-mail</span><br>
                      tech@thinkalternate.com<br />24 X 7 online support<br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 r-mt-30">
                <div class="d-flex justify-content-between mt-4">
                  <div> <img src="../assets/icons/in_icon.svg" />
                    <p class="p1" style="font-size: 12px;margin-top: 5px;">
                      LinkedIn
                    </p>
                  </div>
                  <div> <img src="../assets/icons/git_icon.svg" />
                    <p class="p1" style="font-size: 12px;margin-top: 5px;">
                      Github
                    </p>
                  </div>
                  <div> <img src="../assets/icons/twitter_icon.svg" />
                    <p class="p1" style="font-size: 12px;margin-top: 5px;">
                      Twitter
                    </p>
                  </div>
                  <div> <img src="../assets/icons/yt_icon.svg" />
                    <p class="p1" style="font-size: 12px;margin-top: 5px;">
                      YouTube
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-2 r-mt-30">
                <div class="d-flex justify-content-center">
                  <img src="../assets/THAT_logo.png" width="120" />
                </div>
              </div>
            </div>
            <div class="row iq-mt-40">
              <div class="col-sm-12 text-center">
                <div style="font-size: 12px; font-weight: 300;color: rgb(90, 88, 88);">
                  <span style="border: black 1px solid; border-radius: 50%;padding: 1px 3px; font-size: 10px;"> C</span>
                  <span style="margin-left: 2rem;">2023 ThinkAlternate </span><span style="margin-left: 2rem;">Terms &
                    Conditions</span>
                  <span style="margin-left: 2rem;">Privacy Policy</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- back-to-top -->
    <div id="back-to-top" style="">
      <a class="top" id="top" href="#top"> <i class="ion-ios-arrow-up"></i> </a>
    </div>
  </footer>
</template>

<script>
/* eslint-disable */
import { Icon } from "@iconify/vue";
import { collection, addDoc } from "firebase/firestore";
import { db } from "@/firebase";
import emailjs from "emailjs-com";
import ContactForm from "./ContactForm.vue";

export default {
  name: "FooterVue",
  components: {
    Icon,
    ContactForm,
  },
  data() {
    return {
      name: "",
      mobile: "",
      country: "91",
      email: "",
      message: "",
    };
  },
  methods: {
    async sendMessage() {
      const colRef = collection(db, "messages");
      const dataObj = {
        name: this.name,
        mobile: this.mobile,
        country: this.country,
        email: this.email,
        message: this.message,
        timeStamp: new Date().toLocaleString(),
      };
      const docRef = await addDoc(colRef, dataObj);

      // console.log('Document was created with ID:', docRef.id)
    },
    // sendEmail() {
    //   try {
    //     emailjs.send("service_bc8kgar", "template_fsfcuhf", {
    //       from_name: "rME",
    //       to_name: "SH",
    //       message: "MESSAGES",
    //     });
    //   } catch (error) {
    //     console.log({ error });
    //   }
    //   console.log("success");
    // },
  },
};
</script>

<style>
/* .footer-line-height {
  line-height: 1.5rem;

} */

.footer-links-list ul {
  margin-top: 5rem;
}

.footer-links-list li {
  font-size: 16px;
  font-weight: 100;
}

/* .vl {
  border-left: 3px solid black;
  height: 50px;
} */

/* .footer-top{
  padding: 10px;
  border-top: 1px solid #efefef;
  text-align: center;
  vertical-align: middle;
} */

/* select {
  border-radius: 4px;
} */
/* 
.text-left {
  text-align: left;
}


.info-share {
  margin: 0 0;
  padding: 0;
  text-align: left;
}

.ion-ios-location-outline:before {
  content: "\f455";
}

.iq-footer-box .iq-icon i {
  font-size: 20px;
  float: left;
  margin-right: 20px;
  height: 40px;
  width: 40px;
  border: 1px solid #135ca4;
  background: #ffffff;
  text-align: center;
  line-height: 40px;
  color: #135ca4;
  border-radius: 50%;
} */

/* .fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* .info-share li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  color: #666;
  background: transparent;
  border: 1px solid #666;
  text-align: center;
  border-radius: 50%;
} */
/* 
footer.footer-two .info-share li {
  margin-right: 5px;
  margin-top: 5px;
} */

.custom-footer {
  background-color: #393536;
  color: #ffffff;
}

/* .info-share li {
  display: inline-block;
  list-style: none;
  padding: 0;
} */
/* 
.col-lg-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
} */


/* .mb {
  margin-top: 80px;
}  */

.footer-content {
  font-size: 12px;
}
</style>

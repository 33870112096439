<template>
  <section class="pb-0" id="services">


    <div class="container p-5" style="background-color: #231f20">
      <div class="row">
        <div class="col-8">
          <div class="h1 services-heading">
            The only app developers making your wildest app dreams a reality.
          </div>
          <p class="services-paragraph mb-0" style="margin-top: 5rem;line-height: 1.5;">We'll take over the tricky tech
            stuff.

            And build you a reliable, functional, and user-friendly app.

            So you can serve your customers, grow, streamline your business.

            Without the stress of working with inconsistent freelancers.

            Without expensive inhouse teams draining your funds.

            Without the limitations of no-code platforms.

            Without working with very expensive and slow large corporate teams</p>
          <!-- <p class="services-paragraph mb-0" style="margin-top: 5rem">
            We'll take over the tricky tech stuff.
          </p>
          <p class="services-paragraph mb-0">
            And build you a reliable, functional, and user-friendly app.
          </p>
          <p class="services-paragraph">
            So you can serve your customers, grow, streamline your business.
          </p>
          <p class="services-paragraph mb-0">
            Without the stress of working with inconsistent freelancers.
          </p>
          <p class="services-paragraph mb-0">
            Without expensive inhouse teams draining your funds.
          </p>
          <p class="services-paragraph mb-0">
            Without the limitations of no-code platforms.
          </p>
          <p class="services-paragraph mb-0">
            Without working with very expensive and slow large corporate teams
          </p> -->
        </div>


        <div class="col-4">
          <img src="../assets/images/up_arrrow_image.png" style="top: 4.4rem; left: 3rem; position: relative" />
        </div>
      </div>
    </div>

    <div class="container" style="margin-bottom: 5rem">
      <div class="row" style="margin-top: 7rem">
        <div class="col-3">
          <div class="d-flex justify-content-center mobile_app_development_hover">
            <img src="../assets/home_page_icons/mobile_dev_icon.png" width="100" />
          </div>
          <div style="font-size: 20px; font-weight: 500;line-height: 1.2;margin-top: 1rem; text-align: center;">Mobile
            App<br>Development</div>
        </div>
        <div class="col-3">
          <div class="d-flex justify-content-center web_app_development_hover">
            <img src="../assets/home_page_icons/web_dev_icon.png" width="100" />
          </div>
          <div style="font-size: 20px; font-weight: 500; text-align: center;line-height: 1.2;margin-top: 1rem;">Web
            App<br>Development</div>
        </div>
        <div class="col-3">
          <div class="d-flex justify-content-center ui_ux_design_hover">
            <img src="../assets/home_page_icons/ui_ux_icon.png" width="100" />
          </div>
          <div style="font-size: 20px; font-weight: 500; text-align: center;line-height: 1.2;margin-top: 1rem;">
            UI/UX<br>Design</div>
        </div>
        <div class="col-3">
          <div class="d-flex justify-content-center mobile_app_consulting_hover">
            <img src="../assets/home_page_icons/mobile_consulting_icon.png" width="100" />
          </div>
          <div style="font-size: 20px; font-weight: 500; text-align: center;line-height: 1.2;margin-top: 1rem;">Software<br>Consulting</div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="d-flex justify-content-center">
        <a class="mb-5" style="color: #105da1; font-weight: 700"><u>VIEW ALL SERVICES</u></a>
      </div>
    </div>
  </section>
</template>
<script>
/* eslint-disable */
import { ref } from "vue";
export default {
  name: "OurServieces",
  props: ["servicesList"],
  methods: {
    onClick: function (index) {
      this.servicesList[index].showMore = !this.servicesList[index].showMore;
    },
  },
};
</script>

<style>
.mobile_app_development_hover:hover img {
  content: url("../assets/home_page_icons/mobile_app_dev.svg");
}

.web_app_development_hover:hover img {
  content: url("../assets/home_page_icons/web_dev.svg");
}

.ui_ux_design_hover:hover img {
  content: url("../assets/home_page_icons/product_design.svg");
}

.mobile_app_consulting_hover:hover img {
  content: url("../assets/home_page_icons/software_consulting.svg");
}
</style>

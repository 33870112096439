<template>
    <section id="iq-pricing" style="z-index:10000000;pos">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="title-box">
                        <h2>Blogs and Articles</h2>
                        <p>Latest Posts from our Press</p>
                    </div>
                </div>
                <div v-for="(blog, index) in blogsList" :key="blog.title" :data-index="index" class="col-lg-4 mb-4 mb-lg-0">
                    <div class="card-margin">
                        <div class="iq-pricing-table" data-wow-delay="0.4s">
                            <img class="image-dimes" v-bind:src="blog.image" width="350" />
                            <div class="pricing-header">
                                <h4 class="text-gray mb-4">{{ blog.title }}</h4>
                                <p v-if="(blog.description.length > 80)"><span
                                        v-html="blog.description.substring(0, 80)"></span>...</p>
                                <p v-else v-html="blog.description"></p>
                            </div>
                            <button class="button-read-more" @click="handleClick(index)">Read More</button>
                        </div>
                    </div>
                    <!-- </li> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
/* eslint-disable */
import BlogsDetailView from '../views/BlogDetailView.vue'
export default {
    name: 'BlogsArticles',
    props: ['blogsList'],
    methods: {
        handleClick(id) {
            this.$router.push({
                name: "blogdetail",
                params: {
                    id: id
                }
            });
        }
    },


}
</script>


<style>
.mb-4,
.my-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.image-dimes {
    height: 200px;
}

.iq-pricing-table {
    text-align: center;
    padding: 30px 20px;
    background: none;
    float: left;
    height: 550px;
    width: 100%;
    -webkit-animation: rotate 0.5s;
    -moz-animation: rotate 0.5s;
    animation: rotate 0.5s;
    -webkit-animation: rotate-inverse 0.5s;
    -moz-animation: rotate-inverse 0.5s;
    animation: rotate-inverse 0.5s;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    background: #ffffff;
    box-shadow: 0px 0px 150px 0px rgb(0 0 0 / 10%);
    border-radius: 4px;
}
</style>
<template>
  <!--   <div id="loading">
    <div id="loading-center">
      <img src="./assets/logo.png" alt="loader">
    </div>
  </div> -->

  <header v-if="!$route.meta.hideNavbar" id="main-header" class="org-menu">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <li class="nav-item no-bullets">
              <router-link to="/">
                <img class="img-fluid logo" src="./assets/logo.png" alt="img" width="90" />
              </router-link>
            </li>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <div class="menu-btn" id="menu-btn">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
              </div>
              <span class="ion-navicon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto w-100 justify-content-end">
                <nav>
                  <li class="nav-item">
                    <!-- <router-link to="/about">About us</router-link> -->
                    <a href="#about_us">About us</a>
                  </li>
                  <li class="nav-item">
                    <a href="/#our_solutions">Solutions</a>
                    <!-- <router-link to="#our_solutions">Solutions</router-link> -->
                  </li>
                  <!-- <li class="nav-item">
                    <a href="/#services">Services </a>
                  </li> -->
                  <li class="nav-item">
                    <a href="/#case_Study">Case Study</a>
                    <!--  <router-link to="/blogs">Case Study</router-link> -->
                  </li>
                  <!-- <li class="nav-item"> -->
                  <!-- <a href="/#Industries">Industries</a> -->
                  <!--  <router-link to="/blogs">Industries</router-link> -->
                  <!-- </li> -->

                  <li class="nav-item">
                    <a href="/#contact_us">
                      <button class="btn btn-primary">CONTACT US</button>
                    </a>
                  </li>
                </nav>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <router-view />
  <Footer v-if="!$route.meta.hideNavbar" />
</template>

<script>
/* eslint-disable */
import Footer from "./components/Footer.vue";
export default {
  components: {
    Footer,
  },
};
</script>

<style>
.header .navbar .navbar-nav .nav-item a {
  color: #105da1;
}

header.org-menu .navbar .navbar-nav .nav-item a::before {
  background: transparent;
}

header.org-menu .navbar .navbar-nav .nav-item a:hover,
header.org-menu .navbar .navbar-nav .nav-item a:focus,
header.org-menu .navbar .navbar-nav .nav-item a.active,
header.org-menu .navbar .navbar-nav .nav-item a.active:focus,
header.org-menu .navbar .navbar-nav .nav-item a.active:hover {
  color: #135ca4;
}

header.org-menu .navbar-toggler {
  background: #135ca4;
}
</style>

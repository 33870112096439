<template>
  <section class="pb-0 section-bg-color" id="our_solutions" style="padding-top: 3rem">
    <div class="container mb-5">
      <div class="h3" style="padding-bottom: 3rem; color: #001155">
        We're not just app builders, We are More than that!
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="card solutions-card-background-hover proof_of_concept_hover">
            <div class="card-body solutions-card-background" style="height: 15rem">
              <div>
                <img src="../assets/icons/proof_of_concept.svg"
                  style="max-width: 60px; min-width: 60px; margin-bottom: 2rem" />
              </div>
              <span class="h6 solutions-card-heading">Proof of Concept</span>
              <p class="paragraph-line-height">
                Medium size business at their growth stage build a prototype and
                test the model out in the market to identify new channels of
                revenue.
              </p>
            </div>
            <div class="card-footer text-muted solutions-card-background solutions-card-background">
              Learn More
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="card solutions-card-background-hover minimal_viable_product_hover">
            <div class="card-body solutions-card-background" style="height: 15rem">
              <div>
                <img src="../assets/icons/minimal_viable_product.svg"
                  style="max-width: 60px; min-width: 60px; margin-bottom: 2rem" />
              </div>
              <span class="h6">Minimal Viable Product Development</span>
              <p class="paragraph-line-height">
                Early stage Start-ups build a MVP to test the product market fit
                (PMI) and GTM strategy.
              </p>
            </div>
            <div class="card-footer text-muted solutions-card-background">
              Learn More
            </div>
          </div>
        </div>


        <div class="col-4">
          <div class="card solutions-card-background-hover full_throttle_product_hover">
            <div class="card-body solutions-card-background" style="height: 15rem">
              <div>
                <img src="../assets/icons/full_throttle_product.svg"
                  style="max-width: 60px; min-width: 60px; margin-bottom: 2rem" />
              </div>
              <span class="h6">Product Revamp</span>
              <p class="paragraph-line-height">
                Start-ups, Family run Biz and SMB's as they grow their business
                they are on the look out for technical teams.
              </p>
            </div>
            <div class="card-footer text-muted solutions-card-background">
              Learn More
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="card solutions-card-background-hover fractional_cto_hover">
            <div class="card-body solutions-card-background" style="height: 15rem">
              <div>
                <img src="../assets/icons/fractional_cto.svg"
                  style="max-width: 60px; min-width: 60px; margin-bottom: 2rem" />
              </div>
              <span class="h6">Fractional CTO</span>
              <p class="paragraph-line-height">
                Tech enabled or Tech driven start-up who do not have a technical
                head or co-founder mandatory need this service.
              </p>
            </div>
            <div class="card-footer text-muted solutions-card-background">
              Learn More
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card solutions-card-background-hover mobile_app_support_hover">
            <div class="card-body solutions-card-background" style="height: 15rem">
              <div>
                <img src="../assets/icons/mobile_app_support.svg"
                  style="max-width: 60px; min-width: 60px; margin-bottom: 2rem" />
              </div>
              <span class="h6">Mobile App Support and Maintenance</span>
              <p class="paragraph-line-height">
                Startups nad SMB’s who already have a product but do not have
                the right team to work on issue fixes, upgrades and new feature
                updates.
              </p>
            </div>
            <div class="card-footer text-muted solutions-card-background">
              Learn More
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="card solutions-card-background-hover app_audit_hover">
            <div class="card-body solutions-card-background" style="height: 15rem">
              <div>
                <img src="../assets/icons/app_audit.svg" style="max-width: 60px; min-width: 60px; margin-bottom: 2rem" />
              </div>
              <span class="h6">Mobile App Audit</span>
              <p class="paragraph-line-height">
                Startups and SMB’s who already have a product but unhappy with
                it’s performance and it’s functionality is critical to the
                business.
              </p>
            </div>
            <div class="card-footer text-muted solutions-card-background">
              Learn More
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary text-uppercase mb-5">VIEW ALL SOLUTIONS</button>
      </div>
    </div>
  </section>
</template>
<script>
/* eslint-disable */
import { ref } from "vue";
export default {
  name: "solutions",
  props: ["servicesList"],
  methods: {
    onClick: function (index) {
      this.servicesList[index].showMore = !this.servicesList[index].showMore;
    },
  },
};
</script>

<style>
.section-bg-color {
  background: linear-gradient(179.48deg,
      #4e99de -2.76%,
      #b7cee3 -2.76%,
      #b7cee3 -2.76%,
      #96b8d5 -2.75%,
      #b7cee3 31.68%,
      #cfdfec 63.29%,
      #f3f8fc 99.87%);
}

.proof_of_concept_hover:hover img {
  content: url("../assets/icons/proof_of_concept_hover.svg");
}

.full_throttle_product_hover:hover img {
  content: url("../assets/icons/full_throttle_product_hover.svg");
}

.app_audit_hover:hover img {
  content: url("../assets/icons/app_audit_hover.svg");
}

.minimal_viable_product_hover:hover img {
  content: url("../assets/icons/minimal_viable_product_hover.svg");
}

.fractional_cto_hover:hover img {
  content: url("../assets/icons/fractional_cto_hover.svg");
}

.mobile_app_support_hover:hover img {
  content: url("../assets/icons/mobile_app_support_hover.svg");
}
</style>
